import classNames from 'classnames';
import { graphql, Link, useStaticQuery } from 'gatsby';
import React, { useContext, useEffect, useState } from 'react';
import { ReactComponent as Logo } from '../../../assets/images/techarenan-logo.svg';
import { CategoriesContext } from '../../../contexts';
import { NavigationQuery } from '../../../graphql-generated';
import { isExternalLink } from '../../../utils/isExternalLink';
import { parseLink } from '../../../utils/parseLink';
import './navigation.scss';

export type NavigationProps = {
  additionalMobileLinks?: Array<{ title: any; url: any }> | null;
};

const SEARCH_URL = '/search';
const HOME_URL = '/';

export const Navigation: React.FC<NavigationProps> = ({
  additionalMobileLinks,
}) => {
  const data = useStaticQuery<NavigationQuery>(query);
  const [isMenuOpened, setMenuOpened] = useState<Boolean>(false);

  useEffect(() => {
    const { classList } = document.getElementsByTagName('html')[0];
    if (isMenuOpened) {
      classList.add('no-scroll');
    } else {
      classList.remove('no-scroll');
    }
  }, [isMenuOpened]);

  const { activeCategory, getColorForCategory } = useContext(CategoriesContext);
  const classNameFromActiveCategory = activeCategory
    ? `nav--${getColorForCategory(activeCategory)}`
    : null;

  const primaryNavigationItems = data.primaryNavigation?.menuItems?.nodes ?? [];

  return (
    <nav
      className={classNames(
        'nav',
        {
          'nav--active': isMenuOpened,
        },
        classNameFromActiveCategory
      )}
    >
      <Link to={HOME_URL}>
        <span className="nav__logo">
          <Logo style={{ fill: '#fff' }} />
        </span>
      </Link>
      <ul className="nav__items">
        {primaryNavigationItems.map((item, i) => (
          <li key={item?.label ?? i} className="item">
            <Link
              to={parseLink(item?.url)}
              className={classNames('nav__link', {
                'nav__link--active':
                  activeCategory?.toLowerCase() === item?.label?.toLowerCase(),
              })}
              onClick={() => setMenuOpened(false)}
              activeClassName="nav__link--active"
            >
              {item?.label}
            </Link>
          </li>
        ))}
        {additionalMobileLinks && additionalMobileLinks.length > 0 && (
          <span className="additional-items">
            {additionalMobileLinks.map(({ title, url = '' }) => {
              const isExternalUrl = isExternalLink(url);

              return (
                <li key={title} className="item">
                  {isExternalUrl ? (
                    <a href={url} onClick={() => setMenuOpened(false)}>
                      {title}
                    </a>
                  ) : (
                    <Link
                      to={parseLink(url)}
                      onClick={() => setMenuOpened(false)}
                    >
                      {title}
                    </Link>
                  )}
                </li>
              );
            })}
          </span>
        )}
      </ul>
      <ul className="menu__items">
        <li className="item nav__search">
          <Link
            className="nav__link"
            activeClassName="nav__link--active"
            to={parseLink(SEARCH_URL)}
            onClick={() => setMenuOpened(false)}
          >
            <span>SÖK</span>
          </Link>
        </li>
        <li
          className="nav__icons"
          onClick={() => setMenuOpened((prevIsMenuOpened) => !prevIsMenuOpened)}
        >
          <i className="menu-icon" />
          <i className="menu-close-icon" />
        </li>
      </ul>
    </nav>
  );
};

const query = graphql`
  query NavigationQuery {
    primaryNavigation: wpMenu(locations: { eq: PRIMARY_NAVIGATION }) {
      name
      menuItems {
        nodes {
          label
          url
        }
      }
      id
    }
  }
`;
