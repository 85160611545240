import { format, isToday, isValid, isYesterday } from 'date-fns/esm';
import svLocale from 'date-fns/esm/locale/sv';

/**
 * Smartly parse dates according to how recent it is.
 *
 * The same day = HH:mm
 * yesterday = Igår
 * otherwise only the date
 *
 * @param date
 */
export const formatDate = (date: Date | null | undefined): string => {
  const recentDate = formatRecentDate(date)

  if (recentDate) {
    return recentDate;
  }

  return format(date, 'd MMMM', { locale: svLocale });
}

export const formatDateFull = (date: Date | null | undefined): string => {
  const recentDate = formatRecentDate(date)

  if (recentDate) {
    return recentDate;
  }

  return format(date, 'd MMMM yyyy', { locale: svLocale });
};

const formatRecentDate = (date: Date | null | undefined): string | null => {
  if (!date) return '';

  if (!isValid(date)) return '';

  if (isToday(date)) {
    return format(date, 'HH:mm');
  } else if (isYesterday(date)) {
    return `Igår ${format(date, 'HH:mm')}`;
  }

  return null;
};

export const isDateStringValid = (dateString: string | undefined | null) => {
  if(!dateString) return false;
  if(typeof dateString === 'undefined') return false;
  if(['undefined','null'].indexOf(dateString ?? '') >= 0) return false;
  return true;
}

export const adjustToUtc = (dateString: string | undefined | null) => {
  if(!isDateStringValid(dateString)) {
    return dateString;
  }

  if(!dateString?.includes('T') && dateString?.includes(' ')) {
    const split = dateString.split(' ');
    return `${split[0]}T${split[1]}`;
  }

  return dateString;
}