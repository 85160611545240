import { graphql, useStaticQuery } from 'gatsby';
import React from 'react';
import '../../../assets/styles/main.scss';
import { withProvidingCategories } from '../../../hoc/withProvidingCategories';
import { CookieConfirm } from '../../molecules/cookieConfirm';
import { TakeoverAd } from '../../molecules/takeoverAd';
import { Footer } from '../footer';
import { Header } from '../header';
import './layout.scss';

export type LayoutProps = {
  children: React.ReactNode;
  wrapIntoContainer?: boolean;
};

const Layout: React.FC<LayoutProps> = ({ children }, ref) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `);

  return (
    <div className="page">
      <Header siteTitle={data.site.siteMetadata.title} />
      <main>{children}</main>
      <Footer />
      <CookieConfirm />
      <TakeoverAd />
    </div>
  );
};

export default withProvidingCategories(React.forwardRef(Layout as any) as any);
