import React from 'react';
import './separator.scss';
import classNames from 'classnames';
import { toCamelCase } from '../../../utils/toCamelCase';

export enum SeparatorColor {
  Dark = 'Dark',
  DarkGray = 'DarkGray',
  Light = 'Light',
}

export enum SeparatorType {
  Horizontal = 'Horizontal',
  Vertical = 'Vertical',
}

export type SeparatorProps = {
  color?: SeparatorColor;
  type?: SeparatorType;
  className?: string;
};

export const Separator = React.forwardRef<HTMLDivElement, SeparatorProps>(
  (
    { color = SeparatorColor.Dark, type = SeparatorType.Horizontal, className },
    ref
  ) => (
    <div
      className={classNames(
        'separator',
        className,
        `separator--${toCamelCase(color)}`,
        {
          'separator--vertical': type === SeparatorType.Vertical,
        }
      )}
      ref={ref}
    />
  )
);
