import React from 'react';
import { CategoriesQuery_categories_nodes } from '../graphql-generated';
import { ContentAST_images } from '../graphql-generated';

export type CategoriesContextValue = {
  nodes: CategoriesQuery_categories_nodes[];
  getColorForCategory: (categoryName: string) => string | undefined;
  getHidePageAndLinkForCategory: (categoryName: string) => boolean | undefined;
  activeCategory?: string | null;
  setActiveCategory: (categoryName?: string | null) => void;
};

export const CategoriesContext = React.createContext<CategoriesContextValue>({
  nodes: [],
  getColorForCategory: _ => undefined,
  getHidePageAndLinkForCategory: _ => undefined,
  activeCategory: null,
  setActiveCategory: _ => {},
});

export const ImageContext = React.createContext<
  Map<string, ContentAST_images | null | undefined>
>(new Map());
