import classNames from 'classnames';
import { Link as GatsbyLink } from 'gatsby';
import React from 'react';
import { toCamelCase } from '../../../utils/toCamelCase';
import './button.scss';

export enum ButtonType {
  Blue = 'Blue',
  Dark = 'Dark',
  Submit = 'Submit',
}

export type ButtonProps = {
  type: ButtonType;
  className?: string;
  as?:
    | GatsbyLink<any>
    | React.FunctionComponent
    | React.ComponentClass
    | string;
  href?: string | null;
  target?: string | null;
  onClick?: (event: React.MouseEvent<HTMLElement, MouseEvent>) => void;
};

export const Button = React.forwardRef<
  HTMLElement,
  React.PropsWithChildren<ButtonProps>
>(({ children, type, onClick, as = 'button', href, ...props }, ref) =>
  React.createElement(
    as as any,
    {
      ...props,
      className: classNames(
        'button',
        `button--${toCamelCase(type)}`,
        props.className
      ),
      ref,
      onClick,
      children,
      href,
      type: type === ButtonType.Submit ? 'submit' : '',
      to: href,
    } as React.Attributes
  )
);
