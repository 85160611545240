import Dialog from '@reach/dialog';
import '@reach/dialog/styles.css';
import classNames from 'classnames';
import { graphql, Link, useStaticQuery } from 'gatsby';
import Img from 'gatsby-image';
import React, { useCallback, useEffect, useState } from 'react';
import { ReactComponent as CloseIcon } from '../../../assets/icons/menu-close.svg';
import { ReactComponent as Logo } from '../../../assets/images/techarenan-logo.svg';
import { TakeoverQuery } from '../../../graphql-generated';
import { useIsClientSide } from '../../../hooks/useIsClientSide';
import { useLocalStorageState } from '../../../hooks/useLocalStorageState';
import { parseLink } from '../../../utils/parseLink';
import { Button, ButtonType } from '../../atoms/button';
import './takeoverAd.scss';

type TakeoverLocalState = {
  date: string;
  id: string;
};

export const TakeoverAd = () => {
  const isClientSide = useIsClientSide();
  const [showDialog, setShowDialog] = useState(false);
  const queryData = useStaticQuery<TakeoverQuery>(query);
  const data = queryData.wp?.options?.acfOptions?.takeover ?? null;

  const id = data?.takeoverId || '';

  const [
    localState,
    setLocalState,
  ] = useLocalStorageState<TakeoverLocalState | null>(
    'takeover-ad-status',
    null
  );

  const close = useCallback(() => {
    setShowDialog(false);
    setLocalState({
      date: new Date().toISOString(),
      id: id,
    });
  }, [setLocalState, id]);

  useEffect(() => {
    if (isClientSide) {
      if (localState !== null && localState.id === id) return;
      const timeout = setTimeout(() => {
        setShowDialog(true);
      }, 50);

      return () => clearTimeout(timeout);
    }
  }, [isClientSide]);

  if (!data) return null;
  if (!data.visible) return null;
  if (!isClientSide) return null;

  const topImage = data.topImage?.localFile?.childImageSharp?.fluid ?? null;

  return (
    <div>
      <Dialog
        className="takeover"
        isOpen={showDialog}
        onDismiss={close}
        aria-labelledby="takeover-heading"
      >
        <div className="takeover-header">
          <div className="takeover-logo">
            <Logo />
            <div className="takeover-ad-marking">Annons</div>
          </div>
          <button className="takeover-close-button" onClick={close}>
            <div>Gå vidare till Techarenan.news</div> <CloseIcon />
          </button>
        </div>
        <div className="takeover-top">
          {topImage && (
            <Img fluid={topImage as any} className="takeover-top-img" />
          )}
          <h2 className="takeover-heading" id="takeover-heading">
            {data.heading}
          </h2>
        </div>
        <div className="takeover-content">
          <div
            className={classNames('takeover-actions', {
              'takeover-actions--single': (data.buttons?.length ?? 0) == 1,
            })}
          >
            {data.buttons?.map((button, index) => {
              if (!button) return null;
              return (
                <React.Fragment key={button.link?.url ?? ''}>
                  {index != 0 && data.buttonSeparator && (
                    <div className="takeover-actions__separator">
                      {data.buttonSeparator}
                    </div>
                  )}
                  <div className="takeover-action">
                    <div>{button.text}</div>
                    <Button
                      className="takeover-button"
                      type={ButtonType.Blue}
                      href={button.link?.url ?? undefined}
                      as={Link as any}
                      target={button.link?.target}
                    >
                      {button.label}
                    </Button>
                  </div>
                </React.Fragment>
              );
            })}
          </div>
          {data.content && (
            <div dangerouslySetInnerHTML={{ __html: data.content }} />
          )}
          {data.readMore && (
            <div>
              <Link
                className="takeover-content__link"
                to={parseLink(data.readMore.link?.url)}
                onClick={close}
                target={data.readMore.link?.target ?? undefined}
              >
                {data.readMore.text}
              </Link>
            </div>
          )}
        </div>
        <div className="takeover-footer">
          <div className="takeover-footer__logo">
            {data.footer?.image?.localFile?.childImageSharp?.fluid && (
              <Img
                fluid={data.footer.image.localFile.childImageSharp.fluid as any}
              />
            )}
          </div>
          <div className="takeover-footer__text">{data.footer?.text}</div>
          {data.readMore && (
            <div>
              <Link
                className="takeover-content__link"
                to={parseLink(data.readMore.link?.url)}
                onClick={close}
              >
                Läs mer
              </Link>
            </div>
          )}
        </div>
      </Dialog>
    </div>
  );
};

const query = graphql`
  query TakeoverQuery {
    wp {
      options {
        acfOptions {
          takeover {
            __typename
            takeoverId
            buttonSeparator
            buttons {
              link {
                target
                url
              }
              label
              text
            }
            content
            footer {
              text
              image {
                localFile {
                  childImageSharp {
                    fluid {
                      ...TechImageSharpFluid
                    }
                  }
                }
              }
            }
            heading
            readMore {
              link {
                target
                title
                url
              }
              text
            }
            topImage {
              localFile {
                childImageSharp {
                  fluid {
                    ...TechImageSharpFluid
                  }
                }
              }
            }
            visible
          }
        }
      }
    }
  }
`;
