const GATSBY_URL = process.env.GATSBY_WEBSITE_URL;
const WP_URL = process.env.GATSBY_WORDPRESS_URL;

export const parseLink = (link: string | null | undefined) => {
  if (link == null) return '';

  let url = '';

  try {
    /* why are we doing the following? (I had to fix it) */
    const parsedLink = new URL(link || '');
    const parsedOrigin = parsedLink.origin;

    url = [GATSBY_URL, WP_URL].includes(parsedOrigin)
      ? parsedLink.pathname
      : link;
  } catch (e) {
    url = link || '';
  }

  return url;
};
