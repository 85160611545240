import { graphql, Link, useStaticQuery } from 'gatsby';
import React, { useCallback } from 'react';
import { CookiesQuery } from '../../../graphql-generated';
import { useIsClientSide } from '../../../hooks/useIsClientSide';
import { useLocalStorageState } from '../../../hooks/useLocalStorageState';
import { parseLink } from '../../../utils/parseLink';
import { Button, ButtonType } from '../../atoms/button';
import './cookieConfirm.scss';

export const CookieConfirm = () => {
  const data = useStaticQuery<CookiesQuery>(query);
  const isClientSide = useIsClientSide();

  const [isConfirmed, setIsConfirmed] = useLocalStorageState(
    'cookie-confirm',
    false
  );

  const confirmCookies = useCallback(() => {
    setIsConfirmed(true);
  }, []);

  if (!isClientSide) return null;
  if (isConfirmed) return null;

  return (
    <div className="cookie-confirm">
      <div className="container cookie-confirm__container">
        <div className="cookie-confirm__content">
          Vi använder cookies för att förbättra funktionaliteten på våra sajter,
          för att kunna rikta relevant innehåll och annonser till dig och för
          att vi ska kunna säkerställa att tjänsterna fungerar som de ska.
        </div>
        <div className="cookie-confirm__actions">
          <Button type={ButtonType.Blue} onClick={confirmCookies}>
            Godkänn
          </Button>
          <Link
            to={parseLink(
              data.wp?.options?.acfOptions?.cookies?.readMoreLink?.uri
            )}
          >
            Läs mer
          </Link>
        </div>
      </div>
    </div>
  );
};

const query = graphql`
  query CookiesQuery {
    wp {
      options {
        acfOptions {
          cookies {
            readMoreLink {
              ... on WpMenuItemLinkable {
                uri
              }
            }
          }
        }
      }
    }
  }
`;
