import { graphql, useStaticQuery } from 'gatsby';
import Img from 'gatsby-image';
import React from 'react';
import { FooterQuery } from '../../../graphql-generated';
import { Separator, SeparatorColor } from '../../atoms/separator';
import './footer.scss';

export const Footer: React.FC = () => {
  const data = useStaticQuery<FooterQuery>(query);
  const options = data.wp?.options?.acfFooter;
  // const
  // const { options } = data.options.nodes[0];
  return (
    <footer className="footer no-print">
      <div className="container">
        <section className="footer__heading">{options?.footerHeading}</section>
        {options?.footerImage && (
          <section className="footer__image">
            <Separator color={SeparatorColor.Light} />
            <div className="wrapper">
              <Img
                fluid={
                  options.footerImage.localFile?.childImageSharp?.fluid as any
                }
                alt={options.footerImage.altText ?? ''}
              />
            </div>
          </section>
        )}
        {(options?.footerContent?.length ?? 0) > 0 && (
          <>
            <Separator color={SeparatorColor.Light} />
            <section className="footer__content">
              {options?.footerContent?.map((node, i: number) => {
                if (!node) return null;
                const { content, heading } = node;

                return (
                  <div className="content-item" key={node.heading ?? i}>
                    <h5 className="content-item__heading">{heading}</h5>
                    <div
                      className="content-item__content"
                      dangerouslySetInnerHTML={{ __html: content ?? '' }}
                    />
                  </div>
                );
              })}
            </section>
          </>
        )}
        {options?.footerText && (
          <>
            <Separator color={SeparatorColor.Light} />
            <section
              className="footer__text"
              dangerouslySetInnerHTML={{ __html: options.footerText }}
            ></section>
          </>
        )}
      </div>
    </footer>
  );
};

const query = graphql`
  query FooterQuery {
    wp {
      options {
        acfFooter {
          footerContent {
            ... on WpOptions_Acffooter_FooterContent_Column {
              content
              heading
            }
          }
          footerHeading
          footerImage {
            localFile {
              childImageSharp {
                fluid(quality: 80) {
                  presentationWidth
                  presentationHeight
                  ...TechImageSharpFluid
                }
              }
            }
            altText
          }
          footerText
        }
      }
    }
  }
`;
