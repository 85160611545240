import { graphql, Link, useStaticQuery } from 'gatsby';
import React from 'react';
import { ReactComponent as ArrowIcon } from '../../../assets/icons/arrow-right.svg';
import { HeaderQuery } from '../../../graphql-generated';
import { formatDate } from '../../../utils/formatDate';
import { isExternalLink } from '../../../utils/isExternalLink';
import { parseLink } from '../../../utils/parseLink';
import { Navigation } from '../navigation';
import './header.scss';

export type HeaderProps = {
  siteTitle?: string;
};

export const Header: React.FC<HeaderProps> = ({ siteTitle = '' }) => {
  const data = useStaticQuery<HeaderQuery>(query);
  // const { date, location, name, name_external_link } =
  //   data.options?.nodes?.[0].options?.nextEvent ?? {};
  const event = data.wp?.options?.acfOptions?.nextevent;

  const renderEventDetails = event?.name && event?.date && event?.location;

  const externalNavigationItems =
    data.externalNavigation?.menuItems?.nodes ?? [];
  const renderExternalNavigationItems =
    externalNavigationItems?.length ?? 0 > 0;

  return (
    <>
      {/* a placeholder used to "move" content down since header is position-fixed now */}
      <div className="header-ghost" />
      <header className="header">
        <div className="top-area">
          <span className="event top-area__event">
            {renderEventDetails && (
              <>
                {event?.nameExternalLink?.url ? (
                  <a
                    className="event__title"
                    href={event.nameExternalLink.url}
                    target={event.nameExternalLink.target ?? ''}
                  >
                    {event.name}
                  </a>
                ) : (
                  <span className="event__title">{event?.name}</span>
                )}

                <span className="event__date-place">
                  {formatDate(new Date(event?.date))}&nbsp;{event?.location}
                </span>
              </>
            )}
          </span>
          <span className="top-area__links">
            {renderExternalNavigationItems &&
              externalNavigationItems?.map((item, i) => {
                const isExternal = isExternalLink(item?.url);

                const inner = (
                  <>
                    <span className="arrow-icon">
                      <ArrowIcon />
                    </span>
                    <span>{item?.label}</span>
                  </>
                );

                return isExternal ? (
                  <a href={item?.url || ''} key={item?.url ?? i}>
                    {inner}
                  </a>
                ) : (
                  <Link key={item?.url ?? i} to={parseLink(item?.url)}>
                    {inner}
                  </Link>
                );
              })}
          </span>
        </div>
        <Navigation
          additionalMobileLinks={externalNavigationItems?.map((x) => ({
            title: x?.label,
            url: x?.url,
          }))}
        />
      </header>
    </>
  );
};

const query = graphql`
  query HeaderQuery {
    wp {
      options {
        acfOptions {
          nextevent {
            date
            location
            name
            nameExternalLink {
              url
              target
            }
          }
        }
      }
    }

    externalNavigation: wpMenu(locations: { eq: EXTERNAL_NAVIGATION }) {
      name
      menuItems {
        nodes {
          label
          url
        }
      }
      id
    }
  }
`;
