// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-search-tsx": () => import("./../../../src/pages/search.tsx" /* webpackChunkName: "component---src-pages-search-tsx" */),
  "component---src-templates-category-tsx": () => import("./../../../src/templates/category.tsx" /* webpackChunkName: "component---src-templates-category-tsx" */),
  "component---src-templates-page-index-tsx": () => import("./../../../src/templates/page/index.tsx" /* webpackChunkName: "component---src-templates-page-index-tsx" */),
  "component---src-templates-post-index-tsx": () => import("./../../../src/templates/post/index.tsx" /* webpackChunkName: "component---src-templates-post-index-tsx" */),
  "component---src-templates-post-series-index-tsx": () => import("./../../../src/templates/post-series/index.tsx" /* webpackChunkName: "component---src-templates-post-series-index-tsx" */)
}

