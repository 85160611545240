import { Dispatch, SetStateAction, useEffect, useState } from 'react';

export const useLocalStorageState = <T>(
  name: string,
  defaultValue: T
): [T, Dispatch<SetStateAction<T>>] => {
  const [state, setState] = useState<T>(() => {
    if (typeof window !== 'undefined') {
      let value = localStorage.getItem(getKey(name));
      if (value == null) return defaultValue;

      return JSON.parse(value) as T;
    }

    return defaultValue;
  });

  useEffect(() => {
    localStorage.setItem(getKey(name), JSON.stringify(state));
  }, [state]);

  return [state, setState];
};

const suffix = 'techarenan-';

const getKey = (name: string) => `${suffix}${name}`;
