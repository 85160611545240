import { graphql, useStaticQuery } from 'gatsby';
import React, { useState } from 'react';
import { CategoriesContext } from '../contexts';
import { CategoriesQuery } from '../graphql-generated';

export const withProvidingCategories = <PropsType extends object>(
  Component: React.FC<PropsType>
) => (props: PropsType) => {
  const {
    categories: { nodes },
  } = useStaticQuery<CategoriesQuery>(categoriesQuery);
  const [activeCategory, setActiveCategory] = useState<
    string | null | undefined
  >(null);
  const getColorForCategory = (categoryName: string) => {
    const lowerCased = String(categoryName || '').toLowerCase();
    return (
      nodes.find((node) => String(node.name).toLowerCase() === lowerCased)
        ?.acfCategory?.color ?? undefined
    );
  };
  const getHidePageAndLinkForCategory = (categoryName: string) => {
    const lowerCased = String(categoryName || '').toLowerCase();
    return (
      nodes.find((node) => String(node.name).toLowerCase() === lowerCased)
        ?.acfCategory?.hideLinkAndPage ?? false
    );
  };

  return (
    <CategoriesContext.Provider
      value={{
        nodes,
        getColorForCategory,
        getHidePageAndLinkForCategory,
        activeCategory,
        setActiveCategory,
      }}
    >
      <Component {...props} />
    </CategoriesContext.Provider>
  );
};

const categoriesQuery = graphql`
  query CategoriesQuery {
    categories: allWpCategory {
      nodes {
        acfCategory {
          color
          hideLinkAndPage
        }
        name
      }
    }
  }
`;
