const GATSBY_URL = process.env.GATSBY_WEBSITE_URL;
const WP_URL = process.env.GATSBY_WORDPRESS_URL;

export const isExternalLink = (url: string | null | undefined) => {
  if (url?.length === 0) return true;
  let result = false;

  try {
    let u = new URL(url!);

    if (u.origin !== GATSBY_URL && u.origin !== WP_URL) {
      result = true;
    }
  } catch {}

  return result;
};
